<template>
    <div>
        <h2 class="text-lg text-black font-bold relative">
            <i18n-t keypath="register.explanation.header" />
        </h2>
        <h3 class="mt-8 text-normal text-black font-bold relative">
            <i18n-t keypath="register.explanation.subheader" />
        </h3>
        <template v-if="user.verified == 0">
            <p class="mt-4 leading-7 text-gray-800">
                <i18n-t keypath="register.explanation.verifyPending" />
            </p>
        </template>
        <template v-else>
            <p class="mt-4 leading-7 text-green-600 font-medium">
                <i18n-t keypath="register.explanation.verified" />
            </p>
        </template>
        <h3 class="mt-4 text-normal text-black font-bold relative">
            <i18n-t keypath="register.explanation.get_started" />
        </h3>
        <p class="mt-4 leading-7 text-gray-800">
            <i18n-t keypath="register.explanation.information" />
        </p>
    </div>
</template>

<script>

export default {
    name: "ExplanationStep",    
    computed:{
        user: function(){
            const user = this.$store.getters['getUser'];
            if (user){
                return user;
            } else {
                return {verified: 0};
            }
        },
    },
}
</script>

<style scoped>

</style>
